<template>
  <el-row type="flex" justify="center">
    <el-col :span="6" align="center">
      <h1>404 - Página no encontrada</h1>
      <router-link to="home" class="el-link el-link--default">
        Volver al inicio
      </router-link>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: 'Page404',
  computed: {
    home () {
      const profile = this.$auth.getProfile()
      const home = (profile && profile.isAdmin) ? '/admin' : '/'
      return home
    }
  }
}
</script>
